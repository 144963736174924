class AjaxLoad {

    constructor(uri, options) {

        const settings = {
            requestMethod: 'GET', 
            requestData: null,
            onError: null, 
            onSuccess: null
        };

        this.uri = uri;

        this.settings = Object.assign({}, settings, options);

        this.initialize();
    }

    initialize() {

        const xhr = new XMLHttpRequest();
        
        xhr.open(this.settings.requestMethod, this.uri, true);
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.onload = () => {
            if(xhr.status >= 200 && xhr.status < 400) {
                // Success!
                let result = xhr.responseText;

                if(typeof this.settings.onSuccess === 'function') {
                    this.settings.onSuccess(result);
                }
            } else {
                if(typeof this.settings.onError === 'function') {
                    this.settings.onError(result);
                }
            }
        };

        xhr.send(this.settings.requestData);
    }
}

export default AjaxLoad;