const createElementFromHTML = (htmlString) => {
    const div = document.createElement('div');
    div.innerHTML = htmlString.trim();

    if (div.children.length > 1) {
        const itemsArray = [];
        Array.prototype.push.apply(itemsArray, div.children);

        return itemsArray;
    } else {
        return div.firstChild;
    }
};

const getOffset = (el) => {
    const rect = el.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return {
        top: rect.top + scrollTop,
        left: rect.left + scrollLeft,
        bottom: rect.top + scrollTop + rect.height
    };
};

const loadScript = (source, beforeEl, async = true, defer = true) => {
    return new Promise((resolve, reject) => {
        let script = document.createElement('script');
        const prior = beforeEl || document.getElementsByTagName('script')[0];

        script.async = async;
        script.defer = defer;

        function onloadHander (_, isAbort) {
            if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
                script.onload = null;
                script.onreadystatechange = null;
                script = undefined;

                if (isAbort) {
                    const error = new Error('something went wrong');
                    reject(error);
                } else {
                    resolve(script);
                }
            }
        }

        script.onload = onloadHander;
        script.onreadystatechange = onloadHander;

        script.src = source;
        prior.parentNode.insertBefore(script, prior);
    });
};

const smoothScrollTo = (endX, endY, duration) => {

    let startX = window.scrollX || window.pageXOffset,
        startY = window.scrollY || window.pageYOffset,
        distanceX = endX - startX,
        distanceY = endY - startY,
        startTime = new Date().getTime();

    duration = typeof duration !== 'undefined' ? duration : 400;

    // Easing function
    const easeInOutQuart = (time, from, distance, duration) => {
        if ((time /= duration / 2) < 1) return distance / 2 * time * time * time * time + from;
        return -distance / 2 * ((time -= 2) * time * time * time - 2) + from;
    };

    const timer = window.setInterval(() => {
        let time = new Date().getTime() - startTime,
            newX = easeInOutQuart(time, startX, distanceX, duration),
            newY = easeInOutQuart(time, startY, distanceY, duration);
        if (time >= duration) {
            window.clearInterval(timer);
        }
        window.scrollTo(newX, newY);
    }, 1000 / 60); // 60 fps
};

const tryParseJSON = (jsonString) => {
    try {
        let o = JSON.parse(jsonString);

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object",
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === 'object') {
            return o;
        }
    }
    catch (e) { _log(e); }

    return false;
};

const loadYoutubeApi = () => {
    if (typeof YT === 'undefined') {
        const tag = document.createElement('script');
        tag.src = '//www.youtube.com/iframe_api';

        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
};

export { createElementFromHTML, getOffset, loadScript, smoothScrollTo, tryParseJSON, loadYoutubeApi };
