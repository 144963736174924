// https://stackoverflow.com/a/52265205
/**
 * chrome sometimes fires this function to fast,
 * therefore we wait until everything is loaded before jumping to the element
**/

import { getOffset } from './helper';

const scrollToAnchor = () => {
    const anchor = window.location.hash;
    const $nav = document.querySelector('[data-nav="root"]')
    let $anchorElement = undefined;

    if (anchor !== '') {
        $anchorElement = document.querySelector(anchor);
    }

    if ($anchorElement) {
        let anchorElementTopDistance = getOffset($anchorElement);
        anchorElementTopDistance = parseFloat(anchorElementTopDistance.top).toFixed(0);

        if ($nav && window.matchMedia('(min-width: 1024px').matches) {
            const navHeight = $nav.getBoundingClientRect().height;
            anchorElementTopDistance = anchorElementTopDistance - navHeight;
        }
        window.scrollTo(0, anchorElementTopDistance);
    }
};

const initScrollToAnchor = () => {
    window.setTimeout(() => {
        scrollToAnchor();
    }, 1000);
};

export { initScrollToAnchor }
