import './overlay.scss';
import AjaxLoad from '../../utilities/js/ajax-load';

class Overlay {

    constructor(element, options) {

        const settings = {
            initAttr: 'data-overlay', 
            wrapperAttr: 'wrapper',
            overlayAttr: 'overlay',
            externalAttr: 'data-overlay-external', 
            loader: null, 
            loadTimeout: 100,
            showTimeout: 100,
            hideTimeout: 500
        };

        this.settings = Object.assign({}, settings, options);

        this.$overlayHandle = element;
        this.$overlay = null;
        this.$overlayWrapper = null;

        if(this.settings.loader !== null) {
            this.$loader = _createElementFromHTML(this.settings.loader);
        }

        this.externalContent = false;
        if(this.$overlayHandle.getAttribute(this.settings.externalAttr) !== null) {
            this.externalContent = true;
        }

        this.initialize();
    }

    stopBodyScrolling(bool, touch) {

        let eventName = 'scroll.overlay';
        if(touch === true) {
            eventName = 'touchmove.overlay';
        }

        const freezeVp = (e) => {
            e.preventDefault();
        };

        if (bool === true) {
            document.body.addEventListener(eventName, freezeVp, false);
        } else {
            document.body.removeEventListener(eventName, freezeVp, false);
        }
    }

    buildOverlay() {

        const $overlayHtml = document.createElement('div');
        $overlayHtml.classList.add('overlay');

        $overlayHtml.setAttribute(this.settings.initAttr, 'overlay');

        const $overlayClose = document.createElement('a');
        $overlayClose.classList.add('overlay__close');
        $overlayClose.setAttribute('role', 'button');

        $overlayHtml.appendChild($overlayClose);

        const $overlayInner = document.createElement('div');
        $overlayInner.setAttribute(this.settings.initAttr, 'wrapper');
        $overlayInner.classList.add('overlay__inner');

        $overlayHtml.appendChild($overlayInner);

        document.querySelector('body').appendChild($overlayHtml);

        $overlayClose.addEventListener('click', () => {
            this.closeOverlay();
        });

        $overlayHtml.addEventListener('click', (e) => {

            if(e.target.getAttribute('data-overlay') === 'overlay') {
                this.closeOverlay();
            }
        });

        return [$overlayHtml, $overlayInner];
    }

    setOverlay() {
        this.$overlay = document.querySelector('[' + this.settings.initAttr + '="' + this.settings.overlayAttr + '"]');
        this.$overlayWrapper = document.querySelector('[' + this.settings.initAttr + '="' + this.settings.wrapperAttr + '"]');
        
        if(this.$overlay === null) {
            const $overlayElements = this.buildOverlay();
            this.$overlay = $overlayElements[0];
            this.$overlayWrapper = $overlayElements[1];
        }

        this.$overlayWrapper.scrollTop = 0;

        this.$overlay.API = {
            overlay: this
        };

        window.setTimeout(() => {
            
            this.$overlay.classList.add('is--visible');
            this.stopBodyScrolling(true, Modernizr.ios);

            if(this.externalContent === true) {
                this.setExternalContent(this.$overlayHandle.href);
            } else {
                this.setContent(this.$overlayHandle.href);
            }
            
        }, this.settings.showTimeout);
    }

    setExternalContent(uri) {

        if(this.settings.loader !== null) {
            this.$overlayWrapper.appendChild(this.$loader);
        }
        
        let $overlayContent = document.createElement('iframe');
        $overlayContent.style.width = '100%';
        $overlayContent.style.height = '100%';
        $overlayContent.setAttribute('frameborder', 0);
        $overlayContent.setAttribute('scrolling', 'auto');
        $overlayContent.setAttribute('src', uri);

        this.$overlayWrapper.innerHTML = '';
        this.$overlayWrapper.appendChild($overlayContent);
    }

    setContent(uri) {

        let $overlayContent = this.$overlayWrapper.querySelector('[' + this.settings.initAttr + '="content"]');

        if($overlayContent === null) {
            $overlayContent = document.createElement('div');
            $overlayContent.classList.add('overlay__content');
            $overlayContent.setAttribute(this.settings.initAttr, 'content');
        }

        this.$overlayWrapper.appendChild($overlayContent);
        if($overlayContent.classList.contains('is--visible')) {
            $overlayContent.classList.remove('is--visible');
        }

        if(this.settings.loader !== null) {
            this.$overlayWrapper.appendChild(this.$loader);
        }

        new AjaxLoad(uri, {
            onSuccess: (resultText) => {

                const $result = _createElementFromHTML(resultText);

                const $cleanUrl = $result.querySelector('[data-clean-url="reset"]');

                if ($cleanUrl) {
                    
                    const currentURL = window.location.href;
                    const queryString = '?kb-flyout';
                    const queryStringIndex = currentURL.indexOf(queryString);

                    if (queryStringIndex !== -1) {
                        const domainPart = currentURL.substring(0, queryStringIndex);
                        const newURL = domainPart;

                        history.pushState({}, null, newURL);
                    }
                }

                const customOverlay = $result.getAttribute('data-overlay-style');

                if (customOverlay) {
                    this.$overlay.setAttribute('data-overlay-style', customOverlay);
                    this.$overlay.classList.add('overlay--' + customOverlay);
                } else {
                    this.$overlay.removeAttribute('data-overlay-style');
                    this.$overlay.classList.remove('overlay--' + customOverlay);
                }

                $overlayContent.innerHTML = '';
                if($result.length) {

                    for(let i = 0; i < $result.length; i++) {
                        $overlayContent.appendChild($result[i]);
                    }
                } else {
                    $overlayContent.appendChild($result);
                }

                if(this.settings.loader !== null) {
                    this.$overlayWrapper.removeChild(this.$loader);
                }

                window.setTimeout(() => {
                    $overlayContent.classList.add('is--visible');
                }, this.settings.loadTimeout);

                if(typeof this.settings.onLoaded === 'function') {
                    this.settings.onLoaded($overlayContent);
                }
            }
        });
    }

    closeOverlay() {
        this.$overlay.classList.remove('is--visible');
        window.setTimeout(() => {
            this.stopBodyScrolling(false, Modernizr.ios);
            this.$overlay.parentNode.removeChild(this.$overlay);
            this.$overlay = null;
        }, this.settings.hideTimeout);

    }

    initialize () {
        this.setEvents();
    }

    setEvents() {

        this.$overlayHandle.addEventListener('click', (e) => {
            this.setOverlay();

            e.preventDefault();
        });

        if (this.$overlayHandle.getAttribute('data-ajax-overlay') === 'open') {
            this.$overlayHandle.click();
            this.$overlayHandle.remove();
        }
    }
}

export default Overlay;

