class EventScroller {

    constructor(options) {
        
        const settings = {
            onScroll: null
        };

        this.settings = Object.assign({}, settings, options);
     
        this._window = window;
        this.startPosition = {
            'position': this._window.pageYOffset, 
            'direction': 'down'
        }

        this.lastScrollPos = this._window.pageYOffset;
    }

    initialize() {

        this._window.setTimeout(() => {
            this.scroll(this.getScrollInfo(this._window.pageYOffset));
        }, 1000);
        this.bindEvents();
    }

    bindEvents() {
        
        let ticking = false;

        this._window.addEventListener('scroll', () => {
            let currentPosition = this._window.pageYOffset, 
                scrollInfo;

            if(!ticking) {
                
                this._window.requestAnimationFrame(() => {
                    scrollInfo = this.getScrollInfo(currentPosition);
                    ticking = false;
                    this.scroll(scrollInfo);
                });

                ticking = true;
            }
        });
    }

    // helper function, which returns current scroll position and scroll direction
    getScrollInfo(currScrollPos) {
        let direction = '';
        
        if(this.lastScrollPos > currScrollPos) {
            direction = 'up';
        }
        else if(this.lastScrollPos <= currScrollPos) {
            direction = 'down';
        }

        this.lastScrollPos = currScrollPos;

        return { 'position': currScrollPos, 'direction': direction };
    }

    // fire call back function
    scroll(scrollInfo) {

        if(typeof this.settings.onScroll === 'function') {
            this.settings.onScroll(scrollInfo.position, scrollInfo.direction);
        } 
    }

    // helper function to check, if element is in view
    onScreen($element, tolerance = 0, ignoreBefore = false)  {
        let bounds = $element.getBoundingClientRect();

        if(ignoreBefore) {
            return (((bounds.top + tolerance) - this._window.innerHeight) <= 0 && bounds.bottom > 0);
        } else {
            if(bounds.top <= 0) {
                return true;
            } else {
                return (bounds.top + tolerance - this._window.innerHeight <= 0 && bounds.bottom > 0);
            }
        }
    }
}

export default EventScroller;