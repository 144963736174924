import './search.scss';
import { pushDataLayer } from '../../utilities/js/tracking';

class Search {

    constructor(element, options) {

        const settings = {
            breakpoints: null, 
            initAttr: 'data-search', 
            formAttr: 'form', 
            buttonAttr: 'button[type="submit"]', 
            inputAttr: 'input',
            contentClass: 'has--content',
            visibleClass: 'is--visible'
        };

        this.settings = Object.assign({}, settings, options);

        this.$search = element;
        this.$searchForm = this.$search.querySelector(this.settings.formAttr);
        this.$searchButton = this.$search.querySelector(this.settings.buttonAttr);
        this.$searchField = this.$search.querySelector('[' + this.settings.initAttr + '="' + this.settings.inputAttr + '"]');
        this.focusTimeout = null;

        this.initialize();
    }

    initialize() {

        this.$searchButton.setAttribute('disabled', true);

        this.$deleteButton = document.createElement('a');
        this.$deleteButton.setAttribute('role', 'button');
        this.$deleteButton.classList.add('search__delete');

        this.$searchField.parentNode.insertBefore(this.$deleteButton, this.$searchField);
        this.buttonClick = false;
        
        this.setEvents();
    }

    hasContent(bool) {

        if(bool === true) {
            this.$searchButton.removeAttribute('disabled');
            this.$search.classList.add(this.settings.contentClass);
            this.$deleteButton.classList.add(this.settings.visibleClass);
        } else {
            this.$searchButton.setAttribute('disabled', true);

            if(this.$search.classList.contains(this.settings.contentClass)) {
                this.$search.classList.remove(this.settings.contentClass);
            }

            if(this.$deleteButton.classList.contains(this.settings.visibleClass)) {
                this.$deleteButton.classList.remove(this.settings.visibleClass);
            }
        }
    }

    setEvents() {
        this.$searchField.addEventListener('keyup', (e) => {
            if(this.$searchField.value.length >= 2) {
                this.hasContent(true);
            } else {
                this.hasContent(false);
            }

            if (e.keyCode === 13) {
                const trackingData = {
                    'event': 'search', 
                    'action': 'enter', 
                    'label': 'searchField'
                };

                pushDataLayer(trackingData);
            }
        });

        this.$deleteButton.addEventListener('click', () => {
            this.$searchField.value = '';

            const trackingData = {
                'event': 'deleteSearchTerm', 
                'action': 'click', 
                'label': 'deleteButton'
            };

            pushDataLayer(trackingData);

            this.hasContent(false);
        });

        if(this.settings.breakpoints !== null && window.innerWidth >= this.settings.breakpoints.l) {

            if(Modernizr.csshover) {

                this.$search.addEventListener('mouseenter', () => {
                    const $navToggle = document.getElementById('nav-toggle');
                    $navToggle.checked = false;
                    $navToggle.dispatchEvent(new Event('change'));

                    clearTimeout(this.focusTimeout);
                    this.focusTimeout = window.setTimeout(() => {
                        this.$searchField.focus();
                    }, 250);
                });

                document.addEventListener('click', (e) => {
                    let isClickInside = this.$search.contains(e.target);

                    if (!isClickInside) {
                        if(this.$search.classList.contains(this.settings.contentClass)) {
                            this.$searchField.value = '';
                            this.hasContent(false);
                        }
                    }
                });

            } else {

                this.$search.addEventListener('touchstart', () => {
                    const $navToggle = document.getElementById('nav-toggle');
                    $navToggle.checked = false;
                    $navToggle.dispatchEvent(new Event('change'));

                    if (this.$search.classList.contains('hover')) {
                        this.$search.classList.remove('hover');
                    } else {
                        this.$search.classList.add('hover');
                    }
                    
                    clearTimeout(this.focusTimeout);
                    this.focusTimeout = window.setTimeout(() => {
                        this.$searchField.focus();
                    }, 250);
                });

                document.addEventListener('touchstart', (e) => {
                    let isClickInside = this.$search.contains(e.target);

                    if (!isClickInside) {
                        if(this.$search.classList.contains(this.settings.contentClass)) {
                            this.$search.classList.remove(this.settings.contentClass);
                        }

                        if (this.$search.classList.contains('hover')) {
                            this.$search.classList.remove('hover');
                        }
                    }
                });
            }
            
        }
    }
}

export default Search;
