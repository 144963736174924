import './notification.scss';

class Notification {

    constructor(element, options) {

        const settings = {
            autoclose: false, 
            closeAttr: 'close',
            hasCookie: false,
            cookieLifetime: 0, 
            initAttr: 'data-notification',
            showTimeout: 150,
            hideTimeout: 3000, 
            removeTimeout: 500, 
            wrapperAttr: '.site-header',
            onClose: null
        };

        this.settings = Object.assign({}, settings, options);

        if(typeof element === 'object') {
            this.$notification = element;
        } else {
            this.notificationText = element;
        }

        this.initialize();
    }

    initialize() {

        if(typeof this.notificationText !== 'undefined' && this.notificationText !== null) {
            this.$notification = this.buildNotification(this.notificationText);
        } else {

            if(this.settings.hasCookie === false) {
                this.showNotification();
            } else {
                this.currentCookie = this.getCookie('cookiebanner');
                if(this.currentCookie === '') {
                    this.showNotification();
                } else {
                    this.closeNotification(this.$notification);
                }
            }
        }
    }

    showNotification() {

        const $containerHandle = document.createElement('a');
        $containerHandle.classList.add('notification__handle');

        this.$notification.appendChild($containerHandle);

        $containerHandle.addEventListener('click', (e) => {
            this.closeNotification(this.$notification);
            e.preventDefault();
        });

        clearTimeout(window.showTimer);
        window.showTimer = window.setTimeout(() => {
            this.$notification.classList.add('is--visible');
        }, this.settings.showTimeout);

        this.setEvents();
    }

    buildNotification(nText) {

        let $notification;

        const buildContainer = () => {
            const $container = document.createElement('div'), 
                  $containerInner = document.createElement('div'), 
                  $containerContent = document.createElement('div'), 
                  $containerHandle = document.createElement('a');
            
            $container.classList.add('notification');

            $containerHandle.classList.add('notification__handle');
            $container.appendChild($containerHandle);

            $containerContent.classList.add('notification__content');
            
            $containerInner.classList.add('notification__inner');
            $containerInner.appendChild($containerContent);

            $container.appendChild($containerInner);

            return $container;
        };
        
        const $wrapper = document.querySelector(this.settings.wrapperAttr);

        if($wrapper.querySelector('.notification') === null) {
            $notification = buildContainer();
            $wrapper.appendChild($notification);
        } else {
            $notification = $wrapper.querySelector('.notification');
        }

        $notification.querySelector('.notification__content').innerHTML = nText;

        $notification.querySelector('.notification__handle').addEventListener('click', (e) => {
            this.closeNotification($notification);
            e.preventDefault();
        });

        clearTimeout(window.showTimer);
        window.showTimer = window.setTimeout(() => {
            $notification.classList.add('is--visible');
        }, this.settings.showTimeout);

        if(this.settings.autoclose === true) {
            clearTimeout(window.closeTimer);
            window.closeTimer = window.setTimeout(() => {
                this.closeNotification($notification);
            }, this.settings.hideTimeout);
        }
    }

    closeNotification($notification) {

        $notification.classList.remove('is--visible');

        if(this.settings.hasCookie === true) {
            this.setCookie('cookiebanner', 'read');
        }

        window.setTimeout(() => {
            const $notificationParent = $notification.parentNode;

            if($notificationParent !== null) {
                $notificationParent.removeChild($notification);

                if(typeof this.settings.onClose === 'function') {
                    this.settings.onClose();
                }
            }
        }, this.settings.removeTimeout);
    }

    setEvents() {

        const $closeButton = this.$notification.querySelector('[' + this.settings.initAttr + '="' + this.settings.closeAttr + '"]');
        if($closeButton !== null) {
            $closeButton.addEventListener('click', (e) => {
                this.closeNotification(this.$notification);
                e.preventDefault();
            });
        }

        document.addEventListener('click', (e) => {
            let isClickInside = this.$notification.contains(e.target);

            if (!isClickInside) {
                this.closeNotification(this.$notification);
            }
        });
    }

    setCookie(cookiename, cookievalue) {

        let currentTime = new Date().getTime();
        let expireTime = currentTime + this.settings.cookieLifetime;
        let expireDate = new Date();
        expireDate.setTime(expireTime);

        document.cookie = cookiename + '=' + cookievalue + ';expires=' + expireDate + ';path=/';
    }

    getCookie(cookiename) {
        let name = cookiename + '=';
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }
}

export default Notification;

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const context = eventDetails.$context;

    if (context) {
        const $$notification = context.querySelectorAll('[data-notification="root"]');
        for (let i = 0; i < $$notification.length; i++) {

            const $notification = $$notification[i];

            const notificationAPI = new Notification($notification);
            $notification.notificationAPI = notificationAPI;
        }
    }
});
